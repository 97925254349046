/* eslint-disable prettier/prettier */
// import $ from 'jquery'
var $ = jQuery
// window.$ = $
// window.aabb = 'mai'

const objectFitImages = require('object-fit-images')
// require('jquery-match-height-browserify')
require('slick-slider')

require('select2')

var contact_init = function () {
  return 1
}

;
(function ($) {
  $(function () {
    'use strict'

    /**
		Smooth Scroll
		**/
    $('a[rel="smoothScroll"]').on('click', function (e) {
      e.preventDefault()

      var target = this.hash
      var $target = $(target)

      $('html, body')
        .stop()
        .animate({
            scrollTop: $target.offset().top - 40
          },
          900,
          'swing'
        )
    })

    /**
		MatchHeight
		**/
    // $('.matchHeight').matchHeight()

    /**
		Polyfill object-fit/object-position
		**/
    objectFitImages()

    // anchor smooth scroll
    $(document).on('click', '.anchor', function (event) {
      event.preventDefault()

      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top - 100
        },
        500
      )
    })

    // $('.btn-search-popup').click(function() {
    // $('#popup-search').addClass('open')
    // setTimeout(function() {
    //   $('#popup-search').addClass('opened')
    // }, 400)
    // $('#popup-search input').focus()

    $('.btn-search-popup').click(function () {
      if ($('.right-menu-wrapper').hasClass('show-offcanvas')) {
        $('.right-menu-wrapper').removeClass('show-offcanvas')
        $('.right-menu-wrapper').addClass('no-offcanvas')
      } else {
        $('.right-menu-wrapper').addClass('show-offcanvas')
        $('.right-menu-wrapper').removeClass('no-offcanvas')
      }
    })

    $('.mobmenu-trigger-action').click(function () {
      $('#popup-search').addClass('open')
      setTimeout(function () {
        $('#popup-search').addClass('opened')
      }, 400)
      $('#popup-search input').focus()
    })

    $('.mob-menu a').click(function (e) {
      var ppp = $(this).parent()
      if (ppp.hasClass('menu-item-has-children')) {
        e.preventDefault()
        if (ppp.hasClass('active')) {
          ppp.removeClass('active')
        } else {
          ppp.addClass('active')
        }
      }
    })

    // $('.mob-menu a').toggle(
    //   function(e) {
    //     $(this)
    //       .parent()
    //       .addClass('active')
    //   },
    //   function(e) {
    //     $(this)
    //       .parent()
    //       .removeClass('active')
    //   }
    // )

    // const pageHeader = document.querySelector('.page-header')
    // const openMobMenu = document.querySelector('.open-mobile-menu')
    // const closeMobMenu = document.querySelector('.close-mobile-menu')
    // const topMenuWrapper = document.querySelector('.top-menu-wrapper')
    // // const rightMenuWrapper = document.querySelector('.right-menu-wrapper')
    // const isVisible = 'is-visible'
    // const showOffCanvas = 'show-offcanvas'
    // const noTransition = 'no-transition'
    // let resize

    // // Opening Mobile Menu
    // openMobMenu.addEventListener('click', () => {
    //   topMenuWrapper.classList.add(showOffCanvas)
    // })

    // // Closing Mobile Menu
    // closeMobMenu.addEventListener('click', () => {
    //   topMenuWrapper.classList.remove(showOffCanvas)
    // })

    // $('.close-mobile-menu').click(function() {
    //   var element_id = $(this).attr('data-panel')
    //   $('#' + element_id).removeClass('show-offcanvas')
    // })

    // // Resizing Screen
    // window.addEventListener('resize', () => {
    //   pageHeader.querySelectorAll('*').forEach(function(el) {
    //     el.classList.add(noTransition)
    //   })
    //   clearTimeout(resize)
    //   resize = setTimeout(resizingComplete, 500)
    // })

    // function resizingComplete() {
    //   pageHeader.querySelectorAll('*').forEach(function(el) {
    //     el.classList.remove(noTransition)
    //   })
    // }
    // select2
    // window.requestAnimationFrame(function() {
    $('.select2').select2({
      minimumResultsForSearch: -1
    })
    // })

    $('.wp_slide').slick({
      slidesToShow: 4
    })

    $('.box-relate .row').slick({
      slidesToShow: 3,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }]
    })

    $('#vdo_btn').fancybox({
      width: 600,
      height: 400,
      type: 'iframe',
      // autoSize: true,
      fitToView: true,
      center: true
    })

    $('.btn-form-search').click(function () {
      var focusOnElement = $(this).attr('data-focus-on')
      var element = focusOnElement.concat(' > input')
      setTimeout(function () {
        $(element).focus()
      }, 100)
    })

    $('.btn-open-menu,.slide-fade,.sliding-panel-close').on(
      'click touchstart',
      function (e) {
        var $btn = $(this)
        $btn.addClass('active')
        setTimeout(function () {
          $btn.removeClass('active')
        }, 250)
        $('.slide-content,.slide-fade').toggleClass('is-visible')
        $('#wrapper').toggleClass('is-obscured')
        e.preventDefault()
      }
    )

    $(window).on('load', function () {
      if ($('body').hasClass('single-career')) {
        $('input[name="job_position"]').val(
          $('.section--title h2')
          .text()
          .trim() +
          ' ' +
          $('.section--title span')
          .text()
          .trim()
        )
      }
    })

    // $('#accordion').collapse()
  }) // End Jquery
})(window.jQuery)

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    })
  })
})